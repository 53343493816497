@import '../../styles/colors';
@import '../../styles/spacing';
@import '../../styles/responsive';
@import '../../styles/typography';

$title-max-width: 680px;
$image-max-width: 450px;
$content-max-width: $title-max-width + $image-max-width;
$header-vertical-padding-xl: 106px;

.header_block {
  background: $vroom-teal-hover;
}

.header_block--blue {
  background: $entry-point-blue;
}

.header_block--red {
  background: $entry-point-red;
}

.header_block--green {
  background: $entry-point-green;
}

.header_block--purple {
  background: $entry-point-purple;
}

.header_block--light_blue {
  background: $background_light_blue;
}

.header_block--tan {
  background: $vroom-tan;
}

.header_block--white {
  background: $white;
}

.header_block--light_blue,
.header_block--tan,
.header_block--white {
  .header_block__title {
    color: $vroom-teal;
  }

  .header_block__subtitle {
    color: black;
  }
}

.header_block__content {
  color: $white;
  padding: $spacing-3 $spacing-2;
  text-align: center;

  @include only-on(xl) {
    margin: 0 auto;
    max-width: $content-max-width;
    padding: $header-vertical-padding-xl 0;
    position: relative;
  }
}

.header_block__content--short {
  padding-top: $spacing-2;
  padding-bottom: $spacing-2;

  @include only-on(xl) {
    padding-top: $spacing-3;
    padding-bottom: $spacing-3;
  }
}

.header_block__title {
  @extend .headline_medium;
  margin: 0 auto;

  @include only-on(lgUp) {
    max-width: $title-max-width;
  }
}

.header_block__subtitle {
  @extend .headline_sub_inner;
  margin: 0 auto;
  max-width: 240px;
  padding-top: $spacing-2;

  @include only-on(lgUp) {
    max-width: $title-max-width;
  }
}

.header_block__image {
  padding-top: $spacing-2;

  @include only-on(xl) {
    padding-top: 0;
    position: absolute;
  }
}

.header_block__image--center-right {
  @include only-on(xl) {
    bottom: 50%;
    right: 0;
    transform: translate(0, 50%);
  }
}

.header_block__image--bottom-right {
  @include only-on(xl) {
    bottom: $spacing-3;
    right: 0;
  }
}

.header_block__image--top {
  height: 95px;
  background-repeat: repeat-x;

  @include only-on(xl) {
    height: 160px;
  }
}
